import styled from 'styled-components';
const SELL_TRADE_URL = process.env.REACT_APP_SELL_TRADE_URL || '#';

const MessageWrapper = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-family: Denim;

  > h4 {
    margin-top: 1rem;
    margin-bottom: 0;
    font-size: 1.5rem;
  }
  > p {
    margin-top: 0.5rem;
    > b {
      font-family: 'Denim Semibold';
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpointMedium}) {
    margin: 1rem auto;
    flex-direction: column;
    max-width: 40rem;
    padding: 1rem;
  }
`;

const TradeupLogoImg = styled.img`
  max-width: 20rem;
  min-width: 10rem;
  margin: 2rem auto 0;
`;

interface Props {
  tradeupLogo: string;
  partner: string;
}

const ProgramPausedView = ({ tradeupLogo, partner }: Props): React.ReactElement => {
  return (
    <>
      <TradeupLogoImg src={tradeupLogo} alt="TradeUP with The Pro's Closet" />
      <MessageWrapper>
        <h4>Program Update</h4>
        <p>{`Hello and thank you for your interest in the ${partner} TradeUp program powered by TPC.`}</p>
        <p>
          <b>The program has been permanently suspended.</b>
        </p>
        <p>
          If you are interested in selling a bike, frame, or wheels, please follow this link to{' '}
          <a href={SELL_TRADE_URL}>TPC's Sell // Trade app</a>. If your items are accepted, you will
          receive an offer for cash or TPC credit so you can find your next ride
        </p>
      </MessageWrapper>
    </>
  );
};

export default ProgramPausedView;
